<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-16"
      >
        <v-row>
          <v-col cols="12">
            <p
              class="mb-0 OpenSansSemibold mediumBlueMine punt61Mine"
              style="text-align: center;"
            >
              {{ $t('landing.secFive.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="px-16 mb-10">
          <v-col cols="12">
            <p
              class="standarMine OpenSansLight"
              style="text-align: center;"
            >
              {{ $t('landing.secFive.subtitle') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="px-16">
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}Iberia.svg`"
                :src="`${urlS3}Iberia.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-start"
              style="width: 100px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}onlinetour.png`"
                :src="`${urlS3}onlinetour.png`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}iberojet.png`"
                :src="`${urlS3}iberojet.png`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}Melia.svg`"
                :src="`${urlS3}Melia.svg`"
              >
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- 2do row -->
        <v-row class="px-16">
          <v-col
            cols="4"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}melia_otro.svg`"
                :src="`${urlS3}melia_otro.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="4"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}paradisus.svg`"
                :src="`${urlS3}paradisus.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="4"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}Sol_by_Melia.svg`"
                :src="`${urlS3}Sol_by_Melia.svg`"
              >
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- 3er row -->
        <v-row class="px-16">
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}World2Fly.png`"
                :src="`${urlS3}World2Fly.png`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 100px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}GAVIOTA-TUR.webp`"
                :src="`${urlS3}GAVIOTA-TUR.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}CUBATUR.webp`"
                :src="`${urlS3}CUBATUR.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}viajes_cubanacan.png`"
                :src="`${urlS3}viajes_cubanacan.png`"
              >
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- 4to row -->
        <!-- <v-row class="px-16">
          <v-col cols="4" align="center" class="py-0">
            <div class="d-flex align-center" style="width: 100px; height:150px;  z-index: 11;">
              <v-img lazy-src="@/assets/img/onlinetour.png" src="@/assets/img/onlinetour.png">
              </v-img>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container
        grid-list-sm
        class=""
      >
        <v-row>
          <v-col cols="12">
            <p class="mb-0 OpenSansSemibold mediumBlueMine punt61MineMov">
              {{ $t('landing.secFive.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="12">
            <p class="standarMine OpenSansLight">
              {{ $t('landing.secFive.subtitle') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}Iberia.svg`"
                :src="`${urlS3}Iberia.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}iberojet.png`"
                :src="`${urlS3}iberojet.png`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}Melia.svg`"
                :src="`${urlS3}Melia.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}melia_otro.svg`"
                :src="`${urlS3}melia_otro.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}paradisus.svg`"
                :src="`${urlS3}paradisus.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:100px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}Sol_by_Melia.svg`"
                :src="`${urlS3}Sol_by_Melia.svg`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}World2Fly.png`"
                :src="`${urlS3}World2Fly.png`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 100px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}GAVIOTA-TUR.webp`"
                :src="`${urlS3}GAVIOTA-TUR.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}CUBATUR.webp`"
                :src="`${urlS3}CUBATUR.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 150px; height:150px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}viajes_cubanacan.png`"
                :src="`${urlS3}viajes_cubanacan.png`"
              >
              </v-img>
            </div>
          </v-col>
          <!--<v-col
            cols="6"
            align="center"
            class="py-0"
          >
            <div
              class="d-flex align-center"
              style="width: 100px; height:150px;  z-index: 11;"
            >
              <v-img
                lazy-src="@/assets/img/onlinetour.png"
                src="@/assets/img/onlinetour.png"
              >
              </v-img>
            </div>
          </v-col> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
