<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-10"
      >
        <v-row class="pt-8">
          <v-col cols="12">
            <p
              class="mb-8 OpenSansSemibold mediumBlueMine punt61Mine"
              style="text-align: center;"
            >
              {{ $t('landing.secEight.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-8">
          <v-col
            cols="4"
            class="px-10"
          >
            <v-card
              tile
              height="300"
            >
              <v-card-text class="pa-8 OpenSansLight">
                <div class="quotationMine d-flex justify-center align-center">
                  <v-icon
                    color="white"
                    large
                  >
                    {{ icons.mdiFormatQuoteOpenOutline }}
                  </v-icon>
                </div>
                <span class="standarMine">{{ $t('landing.secEight.opi1') }}</span>
              </v-card-text>
              <div id="one"></div>
              <div id="two"></div>
            </v-card>
            <div class="pt-8 d-flex align-start">
              <v-img
                :lazy-src="`${urlS3}lalo.webp`"
                :src="`${urlS3}lalo.webp`"
                style="max-width: 60px; height:60px"
                class="roundImage"
              >
              </v-img>
              <div class="d-block pl-3">
                <p class="mb-0 standarMine OpenSansSemibold">
                  Valerie Miller
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi1a') }}
                </p>
                <p class="mb-0 standarMine OpenSansSemibold">
                  Eduardo Boné
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi1b') }}<br />
                  {{ $t('landing.secEight.opi1c') }}
                  <a
                    href="https://www.edf.org/"
                    target="_blank"
                  >{{ $t('landing.secEight.opi1d') }}</a>
                </p>
              </div>
            </div>
          </v-col>
          <v-col
            cols="4"
            class="px-10"
          >
            <v-card
              tile
              height="300"
            >
              <v-card-text class="pa-8 OpenSansLight">
                <div class="quotationMine d-flex justify-center align-center">
                  <v-icon
                    color="white"
                    large
                  >
                    {{ icons.mdiFormatQuoteOpenOutline }}
                  </v-icon>
                </div>
                <span class="standarMine">{{ $t('landing.secEight.opi2') }}</span>
              </v-card-text>
              <div id="one"></div>
              <div id="two"></div>
            </v-card>
            <div class="pt-8 d-flex align-start">
              <v-img
                :lazy-src="`${urlS3}Natalia.webp`"
                :src="`${urlS3}Natalia.webp`"
                style="max-width: 60px; height:60px"
                class="roundImage"
              >
              </v-img>
              <div class="d-block pl-3">
                <p class="mb-0 standarMine OpenSansSemibold">
                  Natalia Rossi
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi2a') }}<br />
                  {{ $t('landing.secEight.opi2b') }}
                  <a
                    href="https://www.wcs.org/"
                    target="_blank"
                  >{{ $t('landing.secEight.opi2c') }}</a>
                </p>
              </div>
            </div>
          </v-col>
          <v-col
            cols="4"
            class="px-10"
          >
            <v-card
              tile
              height="300"
            >
              <v-card-text class="pa-8 OpenSansLight">
                <div class="quotationMine d-flex justify-center align-center">
                  <v-icon
                    color="white"
                    large
                  >
                    {{ icons.mdiFormatQuoteOpenOutline }}
                  </v-icon>
                </div>
                <span class="standarMine">{{ $t('landing.secEight.opi3') }}</span>
              </v-card-text>
              <div id="one"></div>
              <div id="two"></div>
            </v-card>
            <div class="pt-8 d-flex align-start">
              <v-img
                :lazy-src="`${urlS3}craig.webp`"
                :src="`${urlS3}craig.webp`"
                style="max-width: 60px; height:60px"
                class="roundImage"
              >
              </v-img>
              <div class="d-block pl-3">
                <p class="mb-0 standarMine OpenSansSemibold">
                  Dr. Craig Orr
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi3a') }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- movil -->
    <div class="d-md-none">
      <v-container
        grid-list-sm
        class="px-10"
      >
        <v-row class="pt-8">
          <v-col cols="12">
            <p
              class="mb-0 OpenSansSemibold mediumBlueMine punt61MineMov"
              style="text-align: center;"
            >
              {{ $t('landing.secEight.title') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-slide-group
        class="py-6"
        show-arrows
      >
        <v-slide-item class="mr-4">
          <v-card
            tile
            height="530"
            width="250"
            elevation="5"
          >
            <v-card-text class="pa-4 OpenSansLight">
              <span class="standarMine">
                <v-icon color="#0198c7">{{ icons.mdiFormatQuoteOpenOutline }}</v-icon>
                {{ $t('landing.secEight.opi1') }}
                <v-icon color="#0198c7">{{ icons.mdiFormatQuoteCloseOutline }} </v-icon>
              </span>
            </v-card-text>
            <div class="d-flex justify-center">
              <v-img
                :lazy-src="`${urlS3}lalo.webp`"
                :src="`${urlS3}lalo.webp`"
                style="max-width: 60px; height:60px"
                align="center"
                justify="center"
                class="roundImage"
              >
              </v-img>
            </div>
            <div class="pb-4 pt-2 d-flex align-start">
              <div class="d-block px-4">
                <p class="mb-0 standarMine OpenSansSemibold">
                  Valerie Miller
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi1a') }}
                </p>
                <p class="mb-0 standarMine OpenSansSemibold">
                  Eduardo Boné
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi1b') }}<br />
                  {{ $t('landing.secEight.opi1c') }}
                  <a
                    href="https://www.edf.org/"
                    target="_blank"
                  >{{ $t('landing.secEight.opi1d') }}</a>
                </p>
              </div>
            </div>
          </v-card>
        </v-slide-item>
        <v-slide-item class="mr-4">
          <v-card
            tile
            height="530"
            width="250"
            elevation="5"
          >
            <v-card-text class="pa-4 OpenSansLight">
              <span class="standarMine">
                <v-icon color="#0198c7">{{ icons.mdiFormatQuoteOpenOutline }}</v-icon>
                {{ $t('landing.secEight.opi2') }}
                <v-icon color="#0198c7">{{ icons.mdiFormatQuoteCloseOutline }} </v-icon>
              </span>
            </v-card-text>
            <div class="d-flex justify-center">
              <v-img
                :lazy-src="`${urlS3}Natalia.webp`"
                :src="`${urlS3}Natalia.webp`"
                style="max-width: 60px; height:60px"
                align="center"
                justify="center"
                class="roundImage"
              >
              </v-img>
            </div>
            <div class="pb-4 pt-2 d-flex align-start">
              <div class="d-block px-4">
                <p class="mb-0 standarMine OpenSansSemibold">
                  Natalia Rossi
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi2a') }}<br />
                  {{ $t('landing.secEight.opi2b') }}
                  <a
                    href="https://www.wcs.org/"
                    target="_blank"
                  >{{ $t('landing.secEight.opi2c') }}</a>
                </p>
              </div>
            </div>
          </v-card>
        </v-slide-item>
        <v-slide-item class="mr-4">
          <v-card
            tile
            height="530"
            width="250"
            elevation="5"
          >
            <v-card-text class="pa-4 OpenSansLight">
              <span class="standarMine">
                <v-icon color="#0198c7">{{ icons.mdiFormatQuoteOpenOutline }}</v-icon>
                {{ $t('landing.secEight.opi3') }}
                <v-icon color="#0198c7">{{ icons.mdiFormatQuoteCloseOutline }} </v-icon>
              </span>
            </v-card-text>
            <div class="d-flex justify-center">
              <v-img
                :lazy-src="`${urlS3}craig.webp`"
                :src="`${urlS3}craig.webp`"
                style="max-width: 60px; height:60px"
                align="center"
                justify="center"
                class="roundImage"
              >
              </v-img>
            </div>
            <div class="pb-4 pt-2 d-flex align-start">
              <div class="d-block px-4">
                <p class="mb-0 standarMine OpenSansSemibold">
                  Dr. Craig Orr
                </p>
                <p class="mb-0 OpenSansLight punt14Mine standarMine">
                  {{ $t('landing.secEight.opi3a') }}
                </p>
              </div>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiFormatQuoteOpenOutline, mdiFormatQuoteCloseOutline,
} from '@mdi/js'

export default {
  data: () => ({
    urlS3: process.env.VUE_APP_URL_S3,
  }),
  setup() {
    return {
      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiFormatQuoteOpenOutline,
        mdiFormatQuoteCloseOutline,
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.punt14Mine {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}

.textBackMine {
  background-color: #0198c7;
}

.roundTextMine {
  border-radius: 500px;
}

#one {
  background-color: white;
  position: absolute;
  width: 20px;
  bottom: -20px;
  left: 40px;
  border-bottom: 20px solid #0199c797;
  border-right: 20px solid transparent;
  z-index: 1;
}

#two {
  background-color: white;
  position: absolute;
  width: 20px;
  left: 10px;
  bottom: -20px;
  left: 60px;
  border-bottom: 20px solid #0199c797;
  border-left: 20px solid transparent;
  z-index: 1;
}

.quotationMine {
  border-radius: 500px;
  background-color: #0198c7;
  position: absolute;
  width: 60px;
  height: 60px;
  top: -30px;
  left: -30px;
  z-index: 1;
}

.roundImage {
  border-radius: 500px;
}
</style>
