<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment v-if="!isLoading">
    <v-btn
      fab
      dark
      large
      :color="botOptions.colorScheme"
      fixed
      right
      bottom
      @click="openChat"
    >
      <v-icon>
        {{ isOpen ? icons.mdiCloseThick : icons.mdiWechat }}
      </v-icon>
    </v-btn>

    <v-container
      v-if="isOpen"
      fluid
      pa-0
      class="container-chat col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-7"
    >
      <v-toolbar
        dense
        class="rounded-lg rounded-b-0"
        :color="botOptions.colorScheme"
      >
        <v-toolbar-title
          class="title"
          style="color: #FFF"
        >
          {{ botOptions.botTitle }}
        </v-toolbar-title>
      </v-toolbar>
      <div
        class="pa-5"
        style="background-color: #FFF; height: 380px; overflow-y: auto"
      >
        <perfect-scrollbar ref="scroll">
          <div
            v-for="(msgGrp, index) in chatData"
            :key="msgGrp.update_id"
            class="d-flex align-start"
            :class="[{ 'flex-row-reverse': msgGrp.agent === 'bot' }, { 'mb-8': chatData.length - 1 !== index }]"
          >
            <div :class="msgGrp.agent === 'bot' ? 'ms-4' : 'me-4'">
              <v-avatar :size="botOptions.avatarSize">
                <v-img :src="msgGrp.agent === 'bot' ? botOptions.botAvatarImg : botOptions.userAvatarImg"></v-img>
              </v-avatar>
            </div>
            <div
              class="d-inline-flex flex-column"
              :class="msgGrp.agent === 'bot' ? 'align-end' : 'align-start'"
            >
              <!--TYPE=TEXT-->
              <span
                v-if="msgGrp.type === 'text'"
                class="py-3 px-4 elevation-1 text-message"
                :class="[msgGrp.agent === 'bot' ? 'rounded-lg rounded-br-0' : 'rounded-lg rounded-bl-0']"
                :style="
                  msgGrp.agent === 'bot'
                    ? `background-color: ${botOptions.msgBgBot};color: ${botOptions.msgColorBot};`
                    : `background-color: ${botOptions.msgBgUser};color: ${botOptions.msgColorUser};`
                "
              >
                <span
                  v-if="msgGrp.type === 'text'"
                  v-html="msgGrp.text"
                ></span>
              </span>
              <!--TYPE=BUTTON-->
              <fragment v-else-if="msgGrp.type === 'button'">
                <span
                  class="py-3 px-4 elevation-1 text-message rounded-lg rounded-br-0"
                  :style="`background-color: ${botOptions.msgBgBot};color: ${botOptions.msgColorBot};`"
                >
                  <span>{{ msgGrp.text }}</span>
                </span>
                <v-btn
                  v-for="(btn, ind) in msgGrp.options"
                  :key="ind"
                  small
                  outlined
                  :color="botOptions.msgBgBot"
                  class="rounded-lg rounded-br-0 mb-3 mt-2"
                  @click="actionButton(btn)"
                >
                  {{ btn.text }}
                </v-btn>
              </fragment>
              <!--TYPE=AUDIO-->
              <fragment v-else-if="msgGrp.type === 'audio'">
                <audio
                  v-for="(btn, ind) in msgGrp.options"
                  :key="ind"
                  :src="rutaPublic + btn.url"
                  controls
                ></audio>
              </fragment>
              <!--TIME-->
              <div
                v-if="msgGrp.agent === 'user'"
                :class="{ 'text-right': msgGrp.agent === 'bot' }"
              >
                <span
                  class="text-xs me-1 text--disabled caption"
                  :class="{ 'text-right': msgGrp.agent === 'bot' }"
                ><b>{{ msgGrp.time | moment('D/M/Y') }}</b></span>
                <span
                  class="text-xs me-1 text--disabled caption"
                  :class="{ 'text-right': msgGrp.agent === 'bot' }"
                >{{
                  msgGrp.time | moment('h:mm a')
                }}</span>
                <!--<v-icon
                  v-if="msgGrp.senderId !== chatData.contact.id"
                  size="16"
                  :color="resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).color"
                >
                  {{ resolveFeedbackIcon(msgGrp.messages[msgGrp.messages.length-1].feedback).icon }}
                </v-icon>-->
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <v-text-field
        v-if="!recorder"
        v-model="chatInputMessage"
        :placeholder="botOptions.inputPlaceholder"
        solo
        hide-details
        height="50"
        autofocus
      >
        <template #append>
          <v-btn
            icon
            @click="record"
          >
            <v-icon>{{ icons.mdiMicrophone }}</v-icon>
          </v-btn>
          <!--<v-btn
            icon
            class="me-3"
          >
            <v-icon>
              {{ icons.mdiAttachment }}
            </v-icon>
          </v-btn>
          class="me-3"
          -->
          <v-btn
            icon
            :disabled="chatInputMessage === null || chatInputMessage === ''"
            @click="sendMessage"
          >
            <v-icon>{{ icons.mdiSendOutline }}</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <fragment v-else>
        <v-progress-linear
          buffer-value="0"
          stream
          reverse
          style="background-color:#FFF;"
        ></v-progress-linear>
        <v-text-field
          placeholder="Grabando...."
          solo
          hide-details
          height="50"
        >
          <template #append>
            <v-btn
              icon
              color="error"
              @click="canceleAudio"
            >
              <v-icon>{{ icons.mdiStop }}</v-icon>
            </v-btn>
            <v-btn
              id="btnParpadeo"
              class="animacion"
              icon
              @click="stopAudio"
            >
              <v-icon>{{ icons.mdiSendClockOutline }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </fragment>
    </v-container>
  </fragment>
</template>

<script>
import {
  mdiWechat,
  mdiCloseThick,
  mdiMicrophone,
  mdiSendClockOutline,
  mdiStop,
  mdiAttachment,
  mdiSendOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    botOptions: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      token: localStorage.getItem('tokenbot'),
      isOpen: false,
      icons: {
        mdiWechat,
        mdiCloseThick,
        mdiMicrophone,
        mdiSendClockOutline,
        mdiStop,
        mdiAttachment,
        mdiSendOutline,
      },
      messagesUser: [],
      user_escribio: false,
      chatData: [],
      chatInputMessage: null,

      // AUDIO
      newAudio: null,
      recorder: null,
      canceleAudioRecord: false,
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.$echo.channel('chat').listen(`.chat.${this.token}`, data => {
      this.chatData = data.data.talking
      if (data.data.archivado) {
        setTimeout(() => {
          localStorage.removeItem('tokenbot')
          window.location.reload()
        }, 2000)
      }

      this.scrollDown()
    })
  },

  created() {
    // EVENTO PARA ENVIAR MENSAJE CON ENTER
    window.addEventListener('keydown', e => {
      if (e.key === 'Enter' && this.chatInputMessage) {
        this.sendMessage()
      }
    })
    this.getMessages()

    // AUDIO
    // this.device = navigator.mediaDevices.getUserMedia({ audio: true })
  },
  methods: {
    getMessages() {
      this.axios
        .get(`chatbot-front/${this.token}`)
        .then(res => {
          this.messagesUser = res.data.data.talking
          this.user_escribio = res.data.data.user_escribio

          // console.log(res.data)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    openChat() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        // AUDIO
        // ACTIVAR MICROFONO
        this.device = navigator.mediaDevices.getUserMedia({ audio: true })

        // PARA MOSTRARLE AL USUARIO QUE SE ABRIO EL CHAT LOS MSG AUTOS
        if (!this.user_escribio) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.messagesUser.length; index++) {
            const element = this.messagesUser[index]
            setTimeout(() => {
              this.chatData.push(element)
            }, 2000 * index)
          }
        } else {
          this.chatData = this.messagesUser
        }
        this.scrollDown()
      } else {
        this.chatData = []
      }
    },
    sendMessage() {
      const message = {
        agent: 'user',
        type: 'text',
        update_id: Math.random()
          .toString(36)
          .substr(2, 9),
        text: this.chatInputMessage,
        options: [],
        visto: false,
        time: this.$moment().format(),
      }

      this.axios
        .post(`chatbot-front/${this.token}`, message)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // console.log(res)
        })

      this.chatInputMessage = null

      this.scrollDown()
    },
    scrollDown() {
      setTimeout(() => {
        const container = this.$refs.scroll
        container.$el.scrollTop = container.$el.scrollHeight
      }, 0)
    },
    actionButton(options) {
      window.open(options.value, '_blank')
    },

    // AUDIO
    async record() {
      this.newAudio = null

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })

      const options = { mimeType: 'audio/webm' }
      const recordedChunks = []
      this.recorder = new MediaRecorder(stream, options)

      this.recorder.addEventListener('dataavailable', e => {
        if (e.data.size > 0) {
          recordedChunks.push(e.data)
        }
      })

      this.recorder.addEventListener('stop', () => {
        if (!this.canceleAudioRecord) {
          this.newAudio = new Blob(recordedChunks)

          // PARA ENVIAR AUDIO GRABADO
          const file = new File([this.newAudio], 'recording.webm')
          const formData = new FormData()
          formData.append('audio', file)

          const message = {
            agent: 'user',
            type: 'audio',
            update_id: Math.random()
              .toString(36)
              .substr(2, 9),
            text: '',
            options: [],
            visto: false,
            time: this.$moment().format(),
          }

          formData.append('data', JSON.stringify(message))

          this.axios
            .post(`chatbot-front/audio/${this.token}`, formData)
            // eslint-disable-next-line no-unused-vars
            .then(res => {})
        } else {
          this.canceleAudioRecord = false
        }
      })

      this.recorder.start()
    },
    async stopAudio() {
      this.recorder.stop()
      this.recorder = null
    },
    async canceleAudio() {
      this.recorder.stop()
      this.canceleAudioRecord = true
      this.newAudio = null
      this.recorder = null
    },
  },
}
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
<style scoped>
.ps {
  height: 100%;
}
.text-message {
  font-size: 12px;
}
.title {
  font-size: 15px !important;
}
.container-chat {
  position: fixed;
  z-index: 1999;
  bottom: 6rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
}

.animacion {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#btnParpadeo {
  width: 100px;
  height: 100px;
  background: pink;
}
</style>
