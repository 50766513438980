<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <MenuSuperior />
      <v-container
        id="home"
        grid-list-xs
        class="px-14"
      >
        <v-row class="pt-10">
          <v-col cols="12">
            <SecOne />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="conecta"
        grid-list-xs
        class="px-15 mt-16 lightBackBlueMine mx-0"
        style="min-width:100%"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecTwo />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="orientada"
        grid-list-xs
        class="px-15 mt-16 mx-0"
        style="min-width:100%"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <Secthree />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="productos"
        grid-list-xs
        class="px-15 mt-16 lightBackBlueV2Mine mx-0"
        style="min-width:100%"
      >
        <v-row class="">
          <v-col cols="12">
            <SecFour />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="socios"
        grid-list-xs
        class="px-15 mt-16 mx-0"
        style="min-width:100%"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecFive />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="div"
        grid-list-xs
        class="px-15 mt-16 mx-0"
        style="min-width:100%"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecSix />
          </v-col>
        </v-row>
      </v-container>
      <!--<v-container
        id="equipo"
        grid-list-xs
        class="px-15 mt-16 mx-0"
        style="min-width:100%"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecSeven />
          </v-col>
        </v-row>
      </v-container>-->
      <v-container
        id="testimonios"
        grid-list-xs
        class="px-15 mt-16 pb-16 lightBackBlueV2Mine mx-0"
        style="min-width:100%"
      >
        <v-row class="">
          <v-col cols="12">
            <SecEight />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="historia"
        grid-list-xs
        class="px-15 mt-16 mx-0"
        style="min-width:100%"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecNine />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        grid-list-xs
        class="px-15 mt-16 darkBlueMine mx-0"
        style="min-width:100%"
      >
        <v-row class="">
          <v-col cols="12">
            <Secfooter />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- movil -->
    <div class="d-md-none">
      <v-app-bar
        fixed
        color="#f4f5fa"
        elevation="1"
        class="pt-0"
      >
        <div
          style="width: 105px; height:60px"
          class="pt-2"
        >
          <v-img
            lazy-src="@/assets/img/wamasolplus-03.svg"
            src="@/assets/img/wamasolplus-03.svg"
          >
          </v-img>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mx-4"
          @click.stop="drawer = !drawer"
        >
          <v-icon>{{ icons.mdiMenu }}</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container id="home">
        <v-row class="pt-5">
          <v-col cols="12">
            <SecOne />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="conecta"
        class="lightBackBlueMine mx-0"
      >
        <v-row class="py-5">
          <v-col cols="12">
            <SecTwo />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="orientada"
        class="mx-0"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <Secthree />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="productos"
        grid-list-xs
        class="lightBackBlueV2Mine mx-0"
      >
        <v-row class="">
          <v-col cols="12">
            <SecFour />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="socios"
        grid-list-xs
        class="mx-0"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecFive />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="div"
        grid-list-xs
        class="mx-0"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecSix />
          </v-col>
        </v-row>
      </v-container>
      <!--<v-container
        id="equipo"
        grid-list-xs
        class="mx-0"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecSeven />
          </v-col>
        </v-row>
      </v-container>-->
      <v-container
        id="testimonios"
        grid-list-xs
        class="pb-16 lightBackBlueV2Mine mx-0"
      >
        <v-row class="">
          <v-col cols="12">
            <SecEight />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        id="historia"
        grid-list-xs
        class="mx-0"
      >
        <v-row class="py-8">
          <v-col cols="12">
            <SecNine />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        grid-list-xs
        class="darkBlueMine mx-0"
      >
        <v-row class="">
          <v-col cols="12">
            <Secfooter />
          </v-col>
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-model="drawer"
        fixed
        style="width: 100%;"
      >
        <v-list
          dense
          class="py-0"
        >
          <!-- Logo y close button -->
          <div class="mt-2 mb-6 mx-4 d-flex justify-space-between align-center">
            <div style="width: 110px; height:34px">
              <v-img
                lazy-src="@/assets/img/wamasolplus-03.svg"
                src="@/assets/img/wamasolplus-03.svg"
              ></v-img>
            </div>
            <a @click="drawer = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </a>
          </div>
          <!-- Sesion -->
          <v-list-item link>
            <v-list-item-content>
              <div class="d-flex justify-space-between align-center">
                <p
                  class="mb-0 menuSupLinkMine standarMine"
                  @click="$router.push({ name: 'auth-login' })"
                >
                  <v-icon
                    medium
                    class="mr-1"
                  >
                    {{ icons.mdiAccountCircle }}
                  </v-icon>
                  {{ $t('landing.lbl.login') }}
                </p>
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mx-4"></v-divider>

          <!-- idioma -->
          <v-list-item
            link
            @click.stop="idioma = !idioma"
          >
            <v-list-item-content>
              <div class="d-flex justify-space-between align-center">
                <p class="mb-0 menuSupLinkMine standarMine">
                  {{ $t('landing.lbl.changeLang') }}
                </p>
                <div class="d-flex justify-space-between align-center">
                  <v-img
                    class="teamPhotoMine mr-2"
                    :lazy-src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                    :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                    :alt="$i18n.locale"
                    style="background-position: inherit;max-width: 25px; max-height:15px"
                  >
                  </v-img>
                  <p class="mb-0 menuSupLinkMine standarMine">
                    <!--{{ $i18n.locale }}-->
                  </p>
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <!-- Navigation Drawer idioma -->
          <v-navigation-drawer
            v-model="idioma"
            right
            fixed
            style="width: 100%;"
          >
            <v-list
              dense
              class="py-0"
            >
              <div class="ma-4 d-flex justify-space-between align-center">
                <a
                  class="d-flex justify-space-between align-center"
                  @click.stop="drawer = !drawer"
                >
                  <v-icon class="mr-4">{{ icons.mdiChevronLeft }}</v-icon>
                  <p class="mb-0 menuSupLinkMine standarMine">
                    {{ $t('landing.lbl.selectLang') }}
                  </p>
                </a>
                <a @click.stop="drawer = !drawer">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </a>
              </div>
              <v-divider></v-divider>
              <v-list-item
                v-for="locale in availableLocales"
                :key="locale.locale"
                :value="locale.locale"
                link
                class="mt-4"
                @click="setLocale(locale.locale)"
              >
                <v-list-item-content>
                  <div class="d-flex justify-start align-center">
                    <v-img
                      class="teamPhotoMine mr-2"
                      :lazy-src="locale.img"
                      :src="locale.img"
                      style="background-position: inherit;max-width: 25px; max-height:15px"
                    >
                    </v-img>
                    <p class="mb-0 menuSupLinkMine standarMine">
                      {{ locale.title }}
                    </p>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>

          <v-divider class="mx-4"></v-divider>

          <v-list-item
            link
            class="mt-4"
          >
            <v-list-item-content>
              <a
                href="#home"
                class="px-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.home') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <p class="mb-0 mx-6 menuSupLinkMine standarMine py-2">
            {{ $t('landing.menu.about') }}
          </p>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#conecta"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.plataform') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#orientada"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.afiliate') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#div"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.marcas') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <!--<v-list-item link>
            <v-list-item-content>
              <a
                href="#equipo"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.team') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>-->

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#historia"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.history') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#productos"
                class="px-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.products') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#socios"
                class="px-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.parnert') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#testimonios"
                class="px-2 mr-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.testimonios') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!--<v-navigation-drawer
        v-model="drawer"
        temporary
        fixed
      >
        <v-list
          dense
          class="mt-6"
        >
          <v-list-item link>
            <v-list-item-content>
              <a
                href="#home"
                class="px-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.home') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <p class="mb-0 mx-6 menuSupLinkMine standarMine py-2">
            {{ $t('landing.menu.about') }}
          </p>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#conecta"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.plataform') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#orientada"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.afiliate') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#div"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.marcas') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#equipo"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.team') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#historia"
                class="px-6"
              >
                <p class="mb-0 menuSupLinkMine">
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>{{ $t('landing.menu.history') }}
                </p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#productos"
                class="px-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.products') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#socios"
                class="px-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.parnert') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <a
                href="#testimonios"
                class="px-2 mr-2"
              >
                <p class="mb-0 menuSupLinkMine">{{ $t('landing.menu.testimonios') }}</p>
              </a>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mb-0 d-flex justify-start align-center ml-6 my-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-img
                    class="teamPhotoMine ml-2"
                    :lazy-src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                    :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                    :alt="$i18n.locale"
                    style="background-position: inherit;max-width: 25px; max-height:15px"
                  >
                  </v-img>
                  <v-icon class="ml-2">
                    {{ icons.mdiChevronDown }}
                  </v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item-group
                  :value="$i18n.locale"
                >
                  <v-list-item
                    v-for="locale in availableLocales"
                    :key="locale.locale"
                    :value="locale.locale"
                    @click="setLocale(locale.locale)"
                  >
                    <a
                      class="d-flex justify-center align-center"
                    >
                      <v-img
                        class="teamPhotoMine"
                        :lazy-src="locale.img"
                        :src="locale.img"
                        style="background-position: inherit;max-width: 25px; max-height:15px"
                      >
                      </v-img>
                      <p class="px-2 mb-0 menuSupLinkMine">{{ locale.title }}
                      </p>
                    </a>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-list-item>

          <v-list-item
            link
            class="mt-3"
          >
            <v-list-item-content>
              <v-btn
                color="#f08319"
                class="OpenSansSemibold"
                style="box-shadow: none !important; color:white"
                rounded
                @click="$router.push({ name: 'auth-login'} )"
              >
                Login
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>-->
    </div>

    <!-- CHAT -->
    <Chat />
  </div>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiChevronRight, mdiMenu,
} from '@mdi/js'

import MenuSuperior from './home/utils/menuSuperior.vue'
import Secfooter from './home/utils/Secfooter.vue'
import SecOne from './home/utils/SecOne.vue'
import SecTwo from './home/utils/SecTwo.vue'
import Secthree from './home/utils/Secthree.vue'
import SecFour from './home/utils/SecFour.vue'
import SecNine from './home/utils/SecNine.vue'
import SecFive from './home/utils/SecFive.vue'
import SecSeven from './home/utils/SecSeven.vue'
import SecEight from './home/utils/SecEight.vue'
import SecSix from './home/utils/SecSix.vue'

import Chat from './chat/front/Index.vue'

export default {
  components: {
    MenuSuperior,
    SecOne,
    SecTwo,
    Secthree,
    SecFour,
    SecFive,
    SecSix,
    // eslint-disable-next-line vue/no-unused-components
    SecSeven,
    SecEight,
    SecNine,
    Secfooter,
    Chat,
  },
  data() {
    return {
      drawer: false,
      idioma: false,
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiChevronRight,
        mdiMenu,
      },
      locales: [
        {
          title: 'Español',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/es.png'),
          locale: 'es',
        },
        {
          title: 'English',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/en.png'),
          locale: 'en',
        },
        {
          title: 'Português',
          // eslint-disable-next-line import/no-unresolved
          img: require('@/assets/images/flags/pt.png'),
          locale: 'pt',
        },
      ],
    }
  },
  computed: {
    availableLocales() {
      return this.locales.filter(i => i.locale !== this.$i18n.locale)
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    },
    // eslint-disable-next-line no-unused-vars
    scrollInto(elementId) {
      this.drawer = false

      /* this.drawer = false
      const section = document.querySelector(`#${elementId}`)
      section.scrollIntoView({
        behavior: 'smooth',
      }) */
    },
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth !important;
}

.lightBackBlueMine {
  background-color: #0198c7;
}

.lightBackBlueV2Mine {
  background-color: #0199c797;
}

.darkBlueMine {
  background-color: #1a5666;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

a {
  text-decoration: none;
}

a > .menuSupLinkMine {
  color: black;
  font-family: OpenSansRegular;
}
</style>
