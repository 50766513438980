<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-16"
      >
        <v-row>
          <v-col cols="12">
            <p
              class="mb-8 OpenSansSemibold mediumBlueMine punt61Mine"
              style="text-align: center;"
            >
              {{ $t('landing.secNine.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row
          id="background"
          class="px-0"
        >
          <v-col
            cols="4"
            class="px-15"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}user.webp`"
                :src="`${urlS3}user.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="8"
            class="px-15"
          >
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">
                2018
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20181') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20182') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20183') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="4"
            class="px-15"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}pyramid.webp`"
                :src="`${urlS3}pyramid.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col
            cols="8"
            class="px-15"
          >
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">
                2020
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20201') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20202') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="4"
            class="px-15"
          >
            <v-img
              :lazy-src="`${urlS3}invEdgar.jpeg`"
              :src="`${urlS3}invEdgar.jpeg`"
              style="width: 250px; height:250px; border-radius: 500px;"
            >
            </v-img>
          </v-col>
          <v-col
            cols="8"
            class="px-15"
          >
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">
                2021
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20211') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="4"
            class="px-15"
          >
            <v-img
              id="planeCenter"
              :lazy-src="`${urlS3}2022.webp`"
              :src="`${urlS3}2022.webp`"
              style="max-width: 250px; width:250px; height:250px; border-radius: 1000px;"
            >
            </v-img>
          </v-col>
          <v-col
            cols="8"
            class="px-15"
          >
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">
                2022
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20221') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20222') }}
                  </p>
                </li>
                <!--<li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20223') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20224') }}
                  </p>
                </li>-->
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="4"
            class="px-15"
          >
            <v-img
              id="planeCenter"
              :lazy-src="`${urlS3}2023.webp`"
              :src="`${urlS3}2023.webp`"
              style="max-width: 250px; width:250px; height:250px; border-radius: 1000px;"
            >
            </v-img>
          </v-col>
          <v-col
            cols="8"
            class="px-15"
          >
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">
                2023
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20231') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20232') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20233') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="4"
            class="px-15"
          >
            <v-img
              id="planeCenter"
              :lazy-src="`${urlS3}2024.webp`"
              :src="`${urlS3}2024.webp`"
              style="max-width: 250px; width:250px; height:250px; border-radius: 1000px;"
            >
            </v-img>
          </v-col>
          <v-col
            cols="8"
            class="px-15"
          >
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine">
                2024
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20241') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20242') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt32Mine">
                    {{ $t('landing.secNine.20243') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-sm>
        <v-row>
          <v-col cols="12">
            <p
              class="mb-8 OpenSansSemibold mediumBlueMine punt61MineMov"
              style="text-align: center;"
            >
              {{ $t('landing.secNine.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row
          id="background"
          class="px-0"
        >
          <v-col
            cols="12"
            align="center"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}user.webp`"
                :src="`${urlS3}user.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">
                2018
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20181') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20182') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20183') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="12"
            align="center"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}pyramid.webp`"
                :src="`${urlS3}pyramid.webp`"
              >
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">
                2020
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20201') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20202') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="12"
            align="center"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}invEdgar.jpeg`"
                :src="`${urlS3}invEdgar.jpeg`"
                style="border-radius: 500px;"
              >
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">
                2021
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20211') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="12"
            align="center"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}2022.webp`"
                :src="`${urlS3}2022.webp`"
                style="border-radius: 500px;"
              >
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">
                2022
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20221') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20222') }}
                  </p>
                </li>
                <!--<li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20223') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20224') }}
                  </p>
                </li>-->
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="12"
            align="center"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}2023.webp`"
                :src="`${urlS3}2023.webp`"
                style="border-radius: 1000px;height:250px;"
              >
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">
                2023
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20231') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20232') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20233') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-0 pt-10">
          <v-col
            cols="12"
            align="center"
          >
            <div
              class=""
              style="width: 250px; height:250px;  z-index: 11;"
            >
              <v-img
                :lazy-src="`${urlS3}2024.webp`"
                :src="`${urlS3}2024.webp`"
                style="border-radius: 1000px;height:250px;"
              >
              </v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <p class="mb-8 OpenSansLight mediumBlueMine punt61Mine d-flex justify-center">
                2024
              </p>
              <ul>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20241') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20242') }}
                  </p>
                </li>
                <li>
                  <p class="mb-2 OpenSansLight standarMine punt14Mine">
                    {{ $t('landing.secNine.20243') }}
                  </p>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  data: () => ({
    urlS3: process.env.VUE_APP_URL_S3,
    model: null,
  }),
  methods: {},
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.punt14Mine {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}

.textBackMine {
  background-color: #0198c7;
}

.roundTextMine {
  border-radius: 500px;
}
</style>
