<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-16"
      >
        <v-row>
          <v-col cols="12">
            <p
              class="OpenSansRegular mediumBlueMine punt52Mine"
              style="text-align: center;"
            >
              {{ $t('landing.secThree.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col
            cols="6"
            class="pr-10"
          >
            <div class="d-flex justify-start align-end mb-5">
              <div style="width: 50px; height:50px">
                <v-img
                  :lazy-src="`${urlS3}Hotel3estrellas.png`"
                  :src="`${urlS3}Hotel3estrellas.png`"
                >
                </v-img>
              </div>
              <p class="mb-0 OpenSansLight mediumBlueMine punt32Mine capLetterMine">
                {{ $t('landing.secThree.proveedor') }}
              </p>
            </div>
            <p class="standarMine OpenSansLight">
              {{ $t('landing.secThree.proveedor1') }}
            </p>
          </v-col>
          <v-col cols="6">
            <div class="d-flex justify-start align-end mb-5">
              <div style="width: 50px; height:50px">
                <v-img
                  :lazy-src="`${urlS3}empresa_viajes.webp`"
                  :src="`${urlS3}empresa_viajes.webp`"
                >
                </v-img>
              </div>
              <p class="mb-0 OpenSansLight mediumBlueMine punt32Mine capLetterMine">
                {{ $t('landing.secThree.agency') }}
              </p>
            </div>
            <p class="standarMine OpenSansLight">
              {{ $t('landing.secThree.agency1') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container
        grid-list-sm
        class=""
      >
        <v-row>
          <v-col cols="12">
            <p class="OpenSansSemibold mediumBlueMine punt52MineMov mb-0">
              {{ $t('landing.secThree.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12">
            <div style="width: 50px; height:50px">
              <v-img
                :lazy-src="`${urlS3}Hotel3estrellas.png`"
                :src="`${urlS3}Hotel3estrellas.png`"
              >
              </v-img>
            </div>
            <p class="OpenSansSemibold mediumBlueMine punt32MineMov capLetterMine">
              {{ $t('landing.secThree.proveedor') }}
            </p>
            <p class="standarMine OpenSansLight punt32MineMov">
              {{ $t('landing.secThree.proveedor1') }}
            </p>
          </v-col>
          <v-col cols="12">
            <div style="width: 50px; height:50px">
              <v-img
                :lazy-src="`${urlS3}empresa_viajes.webp`"
                :src="`${urlS3}empresa_viajes.webp`"
              >
              </v-img>
            </div>
            <p class="OpenSansSemibold mediumBlueMine punt32MineMov capLetterMine">
              {{ $t('landing.secThree.agency') }}
            </p>
            <p class="standarMine OpenSansLight punt32MineMov">
              {{ $t('landing.secThree.agency1') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 22px;
}

.punt35Mine {
  font-size: 20px;
}

.punt35MineMov {
  font-size: 15px;
}

.punt32Mine {
  font-size: 19px;
}

.punt32MineMov {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
