<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-16"
      >
        <v-row>
          <v-col cols="7">
            <p class="mb-0 whiteMine OpenSansRegular punt54Mine">
              {{ $t('landing.secTwo.title') }}
            </p>
            <p class="whiteMine OpenSansLight punt32Mine">
              <span
                class="OpenSansSemibold punt35Mine"
              >{{ $t('landing.secTwo.subtitle') }}</span>
              {{ $t('landing.secTwo.subtitle1') }}
            </p>
          </v-col>
          <v-col cols="5">
            <v-img
              :lazy-src="`${urlS3}LandinPWamaRecursos-01.webp`"
              :src="`${urlS3}LandinPWamaRecursos-01.webp`"
              width="auto"
              height="300px"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container
        grid-list-sm
        class=""
      >
        <v-row>
          <v-col cols="12">
            <p class="whiteMine OpenSansRegular punt54MineMov">
              {{ $t('landing.secTwo.title') }}
            </p>
            <p class="whiteMine OpenSansLight punt32MineMov">
              <span
                class="OpenSansSemibold punt35Mine"
              >{{ $t('landing.secTwo.subtitle') }}</span>
              {{ $t('landing.secTwo.subtitle1') }}
            </p>
          </v-col>
          <v-col cols="12">
            <v-img
              :lazy-src="`${urlS3}LandinPWamaRecursos-01.webp`"
              :src="`${urlS3}LandinPWamaRecursos-01.webp`"
              width="auto"
              height="300px"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt54Mine {
  font-size: 38px;
}

.punt54MineMov {
  font-size: 28px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
