<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-10"
      >
        <v-row class="pt-8">
          <v-col cols="5">
            <div style="width:100%; height:auto">
              <v-img
                :lazy-src="`${urlS3}LandinPWamaRecursos-03.webp`"
                :src="`${urlS3}LandinPWamaRecursos-03.webp`"
                style="max-width: 450px;heigth:auto"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="7">
            <p
              class="capLetterMine OpenSansSemibold punt52Mine mediumBlueMine"
              style="text-align:center"
            >
              {{ $t('landing.secFour.title') }}
            </p>
            <div class="d-flex justify-center align-center mt-10">
              <div class="d-block mx-2">
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-07.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-07.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod1') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-08.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-08.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod2') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-09.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-09.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod3') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-11.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-11.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod4') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-06.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-06.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod5') }}
                </v-btn>
              </div>
              <div class="d-block mx-2">
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-10.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-10.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod6') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-14.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-14.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod7') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-13.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-13.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod8') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-15.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-15.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod9') }}
                </v-btn>
                <v-btn
                  color="white"
                  width="100%"
                  x-large
                  style="justify-content: start;"
                  class="mb-4 punt26Mine OpenSansRegular standarMine"
                  tile
                >
                  <div style="width: 50px; height:50px">
                    <v-img
                      :lazy-src="`${urlS3}LandinPWamaRecursos-12.svg`"
                      :src="`${urlS3}LandinPWamaRecursos-12.svg`"
                    ></v-img>
                  </div>
                  {{ $t('landing.secFour.prod10') }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container>
        <v-row class="">
          <v-col
            cols="12"
            md="5"
          >
            <div style="width:100%; height:auto">
              <v-img
                :lazy-src="`${urlS3}LandinPWamaRecursos-03.webp`"
                :src="`${urlS3}LandinPWamaRecursos-03.webp`"
              ></v-img>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <p
              class="capLetterMine OpenSansSemibold punt52MineMov mediumBlueMine mb-0 pb-5"
              style="text-align:center"
            >
              {{ $t('landing.secFour.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-07.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-07.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod1') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-10.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-10.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod6') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-08.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-08.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod2') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-14.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-14.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod7') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-09.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-09.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod3') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-13.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-13.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod8') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-11.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-11.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod4') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-15.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-15.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod9') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-06.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-06.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod5') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              tile
              width="100%"
              x-large
              color="white"
              class="punt32MineMov OpenSansRegular standarMine pl-2 d-flex justify-start"
            >
              <div style="width: 30px; height:30px">
                <v-img
                  :lazy-src="`${urlS3}LandinPWamaRecursos-12.svg`"
                  :src="`${urlS3}LandinPWamaRecursos-12.svg`"
                ></v-img>
              </div>
              {{ $t('landing.secFour.prod10') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {},
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt35Mine {
  font-size: 20px;
}

.punt35MineMov {
  font-size: 15px;
}

.punt32Mine {
  font-size: 19px;
}

.punt32MineMov {
  font-size: 12px;
}

.punt26Mine {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
