<template>
  <svg
    id="svg5"
    height="30"
    width="30"
    version="1.1"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1668.56 1221.19"
    style="enable-background:new 0 0 1668.56 1221.19;"
    xml:space="preserve"
  >
    <g
      id="layer1"
      :fill="color"
      transform="translate(52.390088,-25.058597)"
    >
      <path
        id="path1009"
        d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99 h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default() {
        return ''
      },
    },
  },
}
</script>
