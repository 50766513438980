<template>
  <div id="econo">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="mt-15"
      >
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <p class="mediumBlueMine OpenSansLight punt55Mine capLetterMine">
              {{ $t('landing.secOne.title') }}
            </p>
            <p class="standarMine OpenSansLight punt32Mine">
              {{ $t('landing.secOne.subtitle') }}
            </p>
            <v-btn
              class="OpenSansSemibold mt-10"
              tile
              color="#1A5666"
              style="box-shadow: none !important; color:white"
              @click="redirectPage()"
            >
              {{ $t('landing.btn.register') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-img
              :src="`${urlS3}LandinPWamaRecursos-02.webp`"
              :lazy-src="`${urlS3}LandinPWamaRecursos-02.webp`"
              width="auto"
              height="500px"
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- movil -->
    <div class="d-md-none">
      <v-container
        grid-list-sm
        class="pt-10"
      >
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <p class="mediumBlueMine OpenSansLight punt55MineMov capLetterMine">
              {{ $t('landing.secOne.title') }}
            </p>
            <p class="standarMine OpenSansLight punt32MineMov">
              {{ $t('landing.secOne.subtitle') }}
            </p>
            <v-btn
              class="OpenSansSemibold mt-10"
              tile
              color="#1A5666"
              style="box-shadow: none !important; color:white"
              @click="$router.push({ name: 'auth-register' })"
            >
              {{ $t('landing.btn.register') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-img
              :lazy-src="`${urlS3}LandinPWamaRecursos-02.webp`"
              :src="`${urlS3}LandinPWamaRecursos-02.webp`"
              width="auto"
              height="400px"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      urlRegister: `${process.env.VUE_APP_URL}register`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {
    redirectPage() {
      // window.open(this.urlRegister, '_blank')

      const route = this.$router.resolve({
        name: 'auth-register',
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.punt55Mine {
  font-size: 40px;
}

.punt55MineMov {
  font-size: 28px;
}

.punt32Mine {
  font-size: 20px;
}

.punt32MineMov {
  font-size: 20px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
