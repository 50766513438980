<template>
  <Chat
    v-if="!isLoading"
    :bot-options="botOptions"
  />
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import Chat from './utils/Chat.vue'

export default {
  components: {
    Chat,
  },
  data() {
    return {
      isLoading: true,
      botOptions: { },
    }
  },
  created() {
    this.verifyUiid()
    setTimeout(() => {
      this.getConfig()
    }, 1000)
  },
  methods: {
    verifyUiid() {
      if (!localStorage.getItem('tokenbot')) {
        localStorage.setItem('tokenbot', uuidv4())
      }
    },
    getConfig() {
      this.axios
        .get('configuration-view/chatbot-setting', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.botOptions = res.data.data.data.data
            this.botOptions.botAvatarImg = process.env.VUE_APP_API_URL_PLUBLIC + this.botOptions.botAvatarImg
            this.botOptions.userAvatarImg = process.env.VUE_APP_API_URL_PLUBLIC + this.botOptions.userAvatarImg
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
