<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-5"
      >
        <v-row>
          <v-col cols="12">
            <p
              class="mb-8 OpenSansSemibold mediumBlueMine punt61Mine"
              style="text-align: center;"
            >
              {{ $t('landing.secSeven.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="px-0">
          <v-col cols="12">
            <v-slide-group
              v-model="model"
              class="pa-4"
              active-class="success"
              show-arrows
            >
              <!-- Juanazoila  -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="450"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-8"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/Juanazoila.webp"
                        src="@/assets/img/Juanazoila.webp"
                        style="max-width: 150px; height:155px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Juanazoila Ortiz
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Guatemala
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }} </b>Pepián guatemalteco
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }} </b>La sociedad de los poetas muertos
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }} </b>Volar
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Yunieski  -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="450"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div
                      class="pt-8"
                      style="width: 150px; height:125px"
                    >
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/Yunieski.webp"
                        src="@/assets/img/Yunieski.webp"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mt-14 mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Yunieski Alonso
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Guatemala
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pescados y Mariscos
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> El pianista
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Volar
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- edgar -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="450"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div
                      class="pt-8"
                      style="width: 150px; height:125px"
                    >
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/Edgar.webp"
                        src="@/assets/img/Edgar.webp"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mt-14 mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Edgar Oramas
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Entrecot en salsa de champiñones
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> La vida es bella
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Omnipresencia
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- ariadna -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div
                      class="pt-8"
                      style="width: 150px; height:125px"
                    >
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/Ariadna.webp"
                        src="@/assets/img/Ariadna.webp"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mt-14 mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Ariadna Martín
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      España
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pasta a la boloñesa entre muchos otros
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Cinema Paradiso
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Felicidad eterna
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Fer -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        style="background-position: center; max-width: 150px; max-height:150px"
                        lazy-src="@/assets/img/Fer2.webp"
                        src="@/assets/img/Fer2.webp"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Fernado García
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pastas
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> La vida es bella
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Hacer un Ironman
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Viktor -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/Viktor.webp"
                        src="@/assets/img/Viktor.webp"
                        style="max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Victor Vargas
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Arroz moro con carne de cerdo
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Avatar
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Leer pensamientos
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- David -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/David.webp"
                        src="@/assets/img/David.webp"
                        style="max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      David Rodríguez
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Ropa vieja
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> La matrix
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Inmortalidad
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Armyri -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/Armirys.webp"
                        src="@/assets/img/Armirys.webp"
                        style="max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Armyris Forte
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Camarones empanados
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> The Marthian
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Teletransportación
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Ariel -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/ariel.webp"
                        src="@/assets/img/ariel.webp"
                        style="max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Ariel Mondelo
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pizza
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Lord of the Rings
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Comer sin engordar
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Ary -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/ary.webp"
                        src="@/assets/img/ary.webp"
                        style="background-position: top; max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Arianne Llera
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pastas
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> The vow
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Saber lo que piensan los demás
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Alina -->
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/alina.webp"
                        src="@/assets/img/alina.webp"
                        style="background-position: inherit;max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Alina Yasells
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Paella Valenciana
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Los puentes de Madison
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Telepatía
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>
              <!-- Vladimir
              <v-slide-item>
                <v-card
                  elevation="5"
                  class="ma-2"
                  height="auto"
                  width="237"
                  tile
                >
                  <v-row
                    class="pb-10"
                    align="center"
                    justify="center"
                  >
                    <div class="pt-8">
                      <v-img
                        class="teamPhotoMine"
                        lazy-src="@/assets/img/vladimir.webp"
                        src="@/assets/img/vladimir.webp"
                        style="background-position: inherit;max-width: 150px; max-height:150px"
                      >
                      </v-img>
                    </div>
                  </v-row>
                  <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
                    <p class="mb-0 d-flex justify-center align-center">
                      Vladimir González
                    </p>
                    <p class="mb-0 d-flex justify-center">
                      Cuba
                    </p>
                  </div>
                  <v-row
                    class="pt-5"
                    align="center"
                    justify="center"
                  >
                    <v-card-text class="pt-6 mx-2">
                      <ul>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Arroz frito
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Pulp Fiction
                        </li>
                        <li class="standarMine OpenSansLight">
                          <b>{{ $t('landing.secSeven.favoritePower') }}</b> Superfuerza
                        </li>
                      </ul>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-slide-item>-->
            </v-slide-group>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- movil -->
    <div class="d-md-none">
      <v-container
        grid-list-sm
        class="px-0"
      >
        <v-row>
          <v-col cols="12">
            <p
              class="mb-0 OpenSansSemibold mediumBlueMine punt61MineMov"
              style="text-align: center;"
            >
              {{ $t('landing.secSeven.title') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-slide-group
        v-model="model"
        class="py-4"
        active-class="success"
        show-arrows
      >
        <!-- Juanazoila  -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="450"
            width="237"
            tile
          >
            <v-row
              class="pb-8"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/Juanazoila.webp"
                  src="@/assets/img/Juanazoila.webp"
                  style="max-width: 150px; height:155px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Juanazoila Ortiz
              </p>
              <p class="mb-0 d-flex justify-center">
                Guatemala
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }} </b>Pepián guatemalteco
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }} </b>La sociedad de los poetas muertos
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }} </b>Volar
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Yunieski  -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="450"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div
                class="pt-8"
                style="width: 150px; height:125px"
              >
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/Yunieski.webp"
                  src="@/assets/img/Yunieski.webp"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mt-14 mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Yunieski Alonso
              </p>
              <p class="mb-0 d-flex justify-center">
                Guatemala
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pescados y Mariscos
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> El pianista
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Volar
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- edgar -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="450"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div
                class="pt-8"
                style="width: 150px; height:125px"
              >
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/Edgar.webp"
                  src="@/assets/img/Edgar.webp"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mt-14 mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Edgar Oramas
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Entrecot en salsa de champiñones
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> La vida es bella
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Omnipresencia
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- ariadna -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div
                class="pt-8"
                style="width: 150px; height:125px"
              >
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/Ariadna.webp"
                  src="@/assets/img/Ariadna.webp"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mt-14 mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Ariadna Martín
              </p>
              <p class="mb-0 d-flex justify-center">
                España
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pasta a la boloñesa entre muchos otros
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Cinema Paradiso
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Felicidad eterna
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Fer -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  style="background-position: center; max-width: 150px; max-height:150px"
                  lazy-src="@/assets/img/Fer2.webp"
                  src="@/assets/img/Fer2.webp"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Fernado García
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pastas
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> La vida es bella
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Hacer un Ironman
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Viktor -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/Viktor.webp"
                  src="@/assets/img/Viktor.webp"
                  style="max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Victor Vargas
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Arroz moro con carne de cerdo
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Avatar
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Leer pensamientos
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- David -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/David.webp"
                  src="@/assets/img/David.webp"
                  style="max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                David Rodríguez
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Ropa vieja
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> La matrix
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Inmortalidad
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Armyri -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/Armirys.webp"
                  src="@/assets/img/Armirys.webp"
                  style="max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Armyris Forte
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Camarones empanados
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> The Marthian
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Teletransportación
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Ariel -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/ariel.webp"
                  src="@/assets/img/ariel.webp"
                  style="max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Ariel Mondelo
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pizza
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Lord of the Rings
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Comer sin engordar
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Ary -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/ary.webp"
                  src="@/assets/img/ary.webp"
                  style="background-position: top; max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Arianne Llera
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Pastas
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> The vow
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Saber lo que piensan los demás
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Alina -->
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/alina.webp"
                  src="@/assets/img/alina.webp"
                  style="background-position: inherit;max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Alina Yasells
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Paella Valenciana
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Los puentes de Madison
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Telepatía
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>
        <!-- Vladimir
        <v-slide-item>
          <v-card
            elevation="5"
            class="ma-2"
            height="auto"
            width="237"
            tile
          >
            <v-row
              class="pb-10"
              align="center"
              justify="center"
            >
              <div class="pt-8">
                <v-img
                  class="teamPhotoMine"
                  lazy-src="@/assets/img/vladimir.webp"
                  src="@/assets/img/vladimir.webp"
                  style="background-position: inherit;max-width: 150px; max-height:150px"
                >
                </v-img>
              </div>
            </v-row>
            <div class="mx-5 pa-2 d-block textBackMine whiteMine roundTextMine">
              <p class="mb-0 d-flex justify-center align-center">
                Vladimir González
              </p>
              <p class="mb-0 d-flex justify-center">
                Cuba
              </p>
            </div>
            <v-row
              class="pt-5"
              align="center"
              justify="center"
            >
              <v-card-text class="pt-6 mx-2">
                <ul>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFoot') }}</b> Arroz frito
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoriteFilm') }}</b> Pulp Fiction
                  </li>
                  <li class="standarMine OpenSansLight">
                    <b>{{ $t('landing.secSeven.favoritePower') }}</b> Superfuerza
                  </li>
                </ul>
              </v-card-text>
            </v-row>
          </v-card>
        </v-slide-item>-->
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  setup() {
    return {
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  data: () => ({
    model: null,
  }),
  methods: {},
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt32Mine {
  font-size: 19px;
}

.capLetterMine {
  text-transform: uppercase;
}

.textBackMine {
  background-color: #0198c7;
}

.roundTextMine {
  border-radius: 500px;
}

.teamPhotoMine {
  border-radius: 500px;
}
</style>
